import * as React from 'react';


export default function Logout() {

  

  if(window.document.location.pathname === "/auth/logout")
  {
    console.log(window.document.location);

  }

  return (
<>LOGOUT</>
  );
}