import axios from 'axios';
import Config from "../../../Configurations/Config"; 

export function GetLocationList(companyId) 
{
    const apiPayload = { Identity: { CompanyId: companyId, UserId: "" } };
    return Exec("locations/all", apiPayload);
}

export function GetLocation(itemId)
{
  const apiPayload =  { ItemId: itemId };
  return  Exec("locations/get", apiPayload);
}

export function EditLocation(model) 
{
  const apiPayload = { Model: model };
  return Exec("locations/edit", apiPayload);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}