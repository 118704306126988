import React, { useState }  from "react";
import DateTimePicker from 'react-datetime-picker';
import ShiftEdit from "../../ShiftEdit";
import SimpleMap from "../../../SimpleMap/SimpleMap";
import PictureList from "../../../PictureList/PictureList";
import MapModal from "../../../SimpleMap/MapModal";
import { convertToHsl } from "daisyui/src/colors/functions";

export default function ZonePreview({ zone }) {



  return (

    <div class=" p-2 my-2 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">

    
    
    <h5 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">{zone.Location.Name}</h5>
    
    <div className="mb-2">
      <label 
        className="block text-sm font-medium text-gray-900 dark:text-gray-300">
        From</label>
        <div>{zone.From}</div>
    </div>

    <div className="mb-2">
      <label 
        className="block text-sm font-medium text-gray-900 dark:text-gray-300">
        User Location</label>
        <MapModal gpsLocation={zone.UserForm?.GpsLocation} />
 
    </div>


    <div className="mb-2">
      <label 
        className="block text-sm font-medium text-gray-900 dark:text-gray-300">
        Time</label>
        <div>{zone.UserForm?.Time ?? "n/a"}</div>
    </div>

    <div className="mb-2">
      <label 
        className="block text-sm font-medium text-gray-900 dark:text-gray-300">
        ClockIn</label>
        <div>{zone.UserForm?.ClockIn ?? "n/a"}</div>
    </div>

    <div className="mb-2">
      <label 
        className="block text-sm font-medium text-gray-900 dark:text-gray-300">
        ClockOut</label>
        <div>{zone.UserForm?.ClockOut ?? "n/a"}</div>
    </div>

    <div className="mb-2">
      <label 
        className="block text-sm font-medium text-gray-900 dark:text-gray-300">
        Pictures</label>
        <div><PictureList /></div>
    </div>

    <div className="mb-2">
      <label 
        className="block text-sm font-medium text-gray-900 dark:text-gray-300">
        Comments</label>
        <div>{zone.UserForm?.Comments ?? "n/a"}</div>
    </div>


    </div>
  );
}