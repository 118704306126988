import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useMatch, useNavigate } from "react-router-dom";

import Breadcrumb from "../Core/Breadcrumb";

import { GetShift, EditShift } from "./Api/ShiftClient";
import useShiftGet from "./Hooks/useShiftGet";
import { ReactSession } from "react-client-session";

import useUserList from "./Hooks/useUserList";
import useLocationList from "./Hooks/useLocationList";

import ZoneList from "./Components/ZoneList/ZoneList";
import ZonePreview from "./Components/ZoneList/ZonePreview";

export default function ShiftPreview() {

  const match = useMatch("/shifts/preview/:shiftId");
  var shiftId = match && match.params && match.params.shiftId;

  const currentUser = ReactSession.get("user");
  const companyId = JSON.parse(currentUser).CompanyId;



  var poolItems = [
    { Name: "Pool for Anyone", Id: 1 },
    { Name: "Only Agents", Id: 2 },
    { Name: "Only Employees", Id: 3 },
    { Name: "One Specific Person", Id: 4 },
  ];

  //===========
  var breadcrumItems = [
    { Name: "Home", Url: "/" },
    { Name: "Shifts", Url: "/shifts" },
    { Name: "Preview", Url: "#" },
  ];
  //===========
  const { userList } = useUserList(companyId);
  const { locationList } = useLocationList(companyId);
  //============
  const { shift, setShift, loading } = useShiftGet(companyId, shiftId);

  const assignee = shift?.Assignee?.Name || "Unassigned";

  return (
    <div className="container mx-auto w-full">
      <div className="flex flex-col container mx-auto w-full">
        <Breadcrumb items={breadcrumItems} />
      </div>

      <div className="container mx-auto my-4">
        {loading ? (
          <>Loading ...</>
        ) : (
          <>

            <div>
            <div className="mb-3">

              <a href={"/shifts/edit/"+shift.Id} type="button" 
              className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Edit</a>
              </div>
  
              <div className="mb-3">
                <label
                  htmlFor="pool"
                  className="block text-sm font-medium text-gray-900 dark:text-gray-300">
                  Pool
                </label>
                <div>{shift && poolItems.find(x => x.Id === shift.Pool)?.Name}</div>
              </div>

              <div className="mb-3">
                <label className="block text-sm font-medium text-gray-900 dark:text-gray-300">
                  Assignee
                </label>
                <div>{assignee}</div>
              </div>

              <div className="mb-12">
                <label className="block text-sm font-medium text-gray-900 dark:text-gray-300">
                  Zones
                </label>
                <div>
                {shift.Zones?.map((zone, index) => (<ZonePreview zone={zone} />))}</div>
              </div>

          

            </div>

           
          </>
        )}
      </div>



    </div>
  );
}