
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetUserList } from "../../User/Api/UserClient";

export default function useUserList(companyId) {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    GetUserList(companyId).then(response => {
      console.log(response);
      setUserList(response.data);
      setLoading(false);
    });


  },[companyId])

  return { userList, loading, error }
}