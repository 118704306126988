
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetShiftList } from "../Api/ShiftClient";

export default function useShiftList(companyId) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    GetShiftList(companyId).then(response => {
      console.log(response);
      setData(response.data);
      setLoading(false);
    });


  },[companyId])

  return { data, loading, error }
}