import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useMatch, useNavigate } from "react-router-dom";

import Breadcrumb from "../Core/Breadcrumb";
import Pagination from "../Core/Pagination";
import ItemView from "./Components/ItemView";

import { GetShift, EditShift } from "./Api/ShiftClient";
import useShiftGet from "./Hooks/useShiftGet";
import { ReactSession } from "react-client-session";
import DateTimePicker from 'react-datetime-picker';

import useUserList from "./Hooks/useUserList";
import useLocationList from "./Hooks/useLocationList";

import { Dropdown } from "flowbite-react";
import ZoneList from "./Components/ZoneList/ZoneList";

export default function ShiftEdit() {
  const navigate = useNavigate();
  const match = useMatch("/shifts/edit/:shiftId");
  var shiftId = match && match.params && match.params.shiftId;

  const currentUser = ReactSession.get("user");
  const companyId = JSON.parse(currentUser).CompanyId;

  //===========
  var breadcrumItems = [
    { Name: "Home", Url: "/" },
    { Name: "Shifts", Url: "/shifts" },
    { Name: "Edit", Url: "#" },
  ];
  //===========

  const { userList } = useUserList(companyId);
  const { locationList } = useLocationList(companyId);

  //============
  const { shift, setShift, loading } = useShiftGet(companyId, shiftId);
  const [saving, setSaving] = useState(false);

  //===========
  const handleOnChange = (e) => {
    let elementName = e.target.name;
    let elementId = e.target.id;
    let attributeValue = e.target.value.toString().trim();
 
    if (elementId === "location")
      setShift({ ...shift, Location: attributeValue });

    if (elementId === "pool") 
      setShift({ ...shift, Pool: attributeValue });

    if (elementId === "assignee")
    {
      const assignee = userList.find(x => x.Id === attributeValue);
      assignee && setShift({ ...shift, Assignee: assignee });
      console.log(assignee);
    }

    if (elementId === "comments")
      setShift({ ...shift, Comments: attributeValue });
  };


  const handleDelete = (event) => {
    event.preventDefault();
    var removedShift = { ...shift, RecordState: 1 };
    setSaving(true);
    const response = EditShift(removedShift).then((response) => {
      console.log(response.data);
      setSaving(false);
      navigate(`/shifts`);
    });
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    setSaving(true);
    const response = EditShift(shift).then((response) => {
      console.log(response.data);
      setSaving(false);
      navigate(`/shifts`);
    });
  };

  const setZones = (zones) => {
    var newShift = { ...shift, Zones: zones };
    console.log(newShift);
    setShift(newShift);
  };

  return (
    <div className="container mx-auto w-full">
      <div className="flex flex-col container mx-auto w-full">
        <Breadcrumb items={breadcrumItems} />
      </div>

      <div className="container mx-auto my-4">
        {loading ? (
          <>Loading ...</>
        ) : (
          <>
            <div>
              {/* 
              <div className="mb-6">
                <label
                  htmlFor="location"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Location
                </label>
                <select className="select select-bordered w-full max-w-xs">
                  <option disabled selected>
                    Select a Location
                  </option>
                  {locationList?.map((location) => (
                    <option value={location.Id}>{location.Name}</option>
                  ))}
                </select>
              </div>*/}

              <div className="mb-6">
                <label
                  htmlFor="pool"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Pool
                </label>
                <select id="pool" onChange={handleOnChange} defaultValue={shift && shift.Pool}  className="select select-bordered w-full max-w-xs">
                  <option value={0} disabled selected>
                    Select a Pool
                  </option>
                  <option value={1}>Pool for Anyone</option>
                  <option value={2}>Only Agents</option>
                  <option value={3}>Only Employees</option>
                  <option value={4}>One Specific Person</option>
                </select>
              </div>

              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Assignee
                </label>

                <select id="assignee" onChange={handleOnChange} defaultValue={shift?.Assignee?.Id}  className="select select-bordered w-full max-w-xs">
                  <option value={0} disabled selected>
                    Select a User
                  </option>
                  {userList?.map((user) => (
                    <option value={user.Id}>{user.Name}</option>
                  ))}
                </select>
              </div>

            </div>

            <ZoneList setZones={setZones} zones={shift.Zones} locations={locationList} />
            
            <button onClick={handleDelete} type="button" 
              className="my-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Delete</button>

            <button onClick={handleSubmit} type="button" 
              className="my-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Save</button>

          </>
        )}
      </div>



    </div>
  );
}
