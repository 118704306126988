import axios from 'axios';
import Config from "../../../Configurations/Config"; 

export function GetClientList(companyId) 
{
    const apiPayload = { Identity: { CompanyId: companyId, UserId: "" } };
    return Exec("clients/all", apiPayload);
}

export function EditClient(model) 
{
  const apiPayload = { Model: model };
  return Exec("clients/edit", apiPayload);
}

export function GetClient(clientId)
{
  const apiPayload =  { ItemId: clientId };
  return  Exec("clients/get", apiPayload);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}