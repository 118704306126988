import * as React from 'react';

import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";

import Breadcrumb from '../Core/Breadcrumb';

import { SendSupport } from "./Api/SupportClient";

export default function SupportPage() {

  const [form, setForm] = useState({
    Name:"",
    Email:"",
    Message:""
  });
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();

  const handleOnBlur = e => {
    let elementName = e.target.name;
    let elementId = e.target.id;
    let attributeValue = e.target.value.toString().trim();

    if(elementId === "name") setForm({ ...form, Name: attributeValue });
    if(elementId === "email") setForm({ ...form, Email: attributeValue });
    if(elementId === "message") setForm({ ...form, Message: attributeValue });
  }

  const handleSubmit = event => {
    event.preventDefault();
    setSaving(true);
    SendSupport(form).then(response =>{
      setSaving(false);
      navigate("/support/thank-you");
    });
  };

  var breadcrumItems = [ {Name:"Home", Url:"/"}, {Name:"Support", Url:"/support"} ];
  


  return (
    <div class="container max-w-lg px-4 ">

    <div class="flex flex-col container mx-auto w-full">
      <Breadcrumb items={breadcrumItems} />
    </div>

    <form className="my-5" onSubmit={handleSubmit}>
    <div class="mb-6">
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
        <input type="text" 
            id="name" onBlur={handleOnBlur}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Name" required/>
    </div> 

    <div class="mb-6">
        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
        <input type="email" 
        id="email" onBlur={handleOnBlur}
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="john.doe@company.com" required/>
    </div> 

    <div class="mb-6">
        <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Message</label>
        <textarea id="message" onBlur={handleOnBlur}
        rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Message" required></textarea>
    </div> 

    <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      {saving ? <>Sending</>:<>Submit</>}
    </button>
    </form>

    </div>
  );
}