import * as React from "react";

import logo from "../Assets/Images/shift.png";

const roleList = [
  { Name: "Admin", Value: 2 },
  { Name: "User", Value: 3 },
  { Name: "Client", Value: 4 },
  { Name: "Agent", Value: 5 },
];

export default function ItemView({ shift }) {

  const assignee = shift?.Assignee?.Name || "Unassigned";
  const location = shift?.Zones[0]?.Location?.Name || "No Location";

  return (
    <a href={`/shifts/preview/${shift.Id}`} >
    <li class="flex flex-row">
      <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
        <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
            <img
              alt="profil"
              src={logo}
              class="mx-auto object-cover  h-10 w-10" />
        </div>
        <div class="flex-1 pl-1">
          <div class="font-medium dark:text-white text-sm">{location}</div>
          <div class="text-gray-600 dark:text-gray-200 text-sm">{assignee}</div>
        </div>
      </div>
    </li>
    </a>
  );
}
