
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useMatch, useNavigate } from "react-router-dom";

import Breadcrumb from '../Core/Breadcrumb';
import Pagination from '../Core/Pagination';
import ItemView from './Components/ItemView';

//====
import { GetClient, EditClient } from "./Api/ClientApi"
import useClientGet from "./Hooks/useClientGet";
import { ReactSession } from 'react-client-session';
//====

export default function ClientEdit() {

  const navigate = useNavigate();
  const match = useMatch("/clients/edit/:clientId");
  var clientId = match && match.params && match.params.clientId;
  const currentUser = ReactSession.get("user"); 
  const companyId = JSON.parse(currentUser).CompanyId;

  //===========
  var breadcrumItems = [ {Name:"Home", Url:"/"}, {Name:"Clients", Url:"/clients"}, {Name:"Edit", Url:"#"} ];
  var items = [{ Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }];
  //===========
  
  const { client, setClient, loading } = useClientGet(companyId, clientId);
  const [saving, setSaving ] = useState(false);

  //===========
  const handleOnBlur = e => {
    let elementName = e.target.name;
    let elementId = e.target.id;
    let attributeValue = e.target.value.toString().trim();
    //========
    if(elementId === "name") setClient({ ...client, Name: attributeValue });
    if(elementId === "address") setClient({ ...client, Address: attributeValue });
    if(elementId === "description") setClient({ ...client, Description: attributeValue });
    //========
  }


  const handleSubmit = event => {
    event.preventDefault();
    setSaving(true);
    const response = EditClient(client).then(response => {
      console.log(response.data);
      setSaving(false);
      if(clientId === "new")
      {
        navigate(`/clients`);
        return;
      }
      toast.success("Saved");
    });
  };


  return (

  <div className="container mx-auto w-full">

    <div className="flex flex-col container mx-auto w-full">
    <Breadcrumb items={breadcrumItems} />
    </div>

    <div class="flex my-4 flex-col container mx-auto">
    {loading ? <>Loading ...</> :
    <>


    <form onSubmit={handleSubmit}>

    <div class="mb-6">
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
        <input type="text" 
          id="name" onBlur={handleOnBlur}
          defaultValue={client && client.Name}
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Name" required/>
    </div> 

    <div class="mb-6">
        <label for="address" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Address</label>
        <input type="text" 
          id="address" onBlur={handleOnBlur}
          defaultValue={client && client.Address}
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Address" required/>
    </div> 

    <div class="mb-6">
        <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Description</label>
        <input type="text" 
          id="description" onBlur={handleOnBlur}
          defaultValue={client && client.Description}
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description" required/>
    </div> 


    <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      {saving ? <>Sending</>:<>Save</>}
    </button>
    </form>
    </>}

    </div>

    </div>
  );
}