
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetShift } from "../Api/ShiftClient";


export default function useShiftGet(companyId, shiftId) {

  const [shift, setShift] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {


    setLoading(true);
    if(!shiftId || shiftId === "new")
    {
     const defaultState = {
            Id:"",
            CompanyId: companyId,
            CreatedAt:"",
            Zones:[],
            RecordState:0
         }
      setShift(defaultState);
      setLoading(false);
      return;
    }

    GetShift(shiftId).then(response => {
        setShift(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[shiftId, companyId])

  return { shift, setShift, loading, error }
}