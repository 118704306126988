import * as React from 'react';
import toast from 'react-hot-toast';

import logo from "../Auth/Assets/Images/logo.png"; //'/../Assets/Images/project_icon.png';

export default function Footer() {

    const handleNotImplemented = ()=>
    {
        toast.success("Not implemented");
    };

  return (
<div></div>
  );
}