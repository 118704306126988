import React, { useState }  from "react";
import DateTimePicker from 'react-datetime-picker';


export default function ZoneView({ zone, update, remove }) {


  

  const [fromDate, setFromDate] = useState(zone ? new Date(zone.From) : new Date());





  const fromDateChange = (e) => {

    var newDate = e.toISOString();
    const newZone = {...zone, From:newDate};
    update(newZone);
    setFromDate(e);

  };


  return (

    <div class="max-w-sm p-2 my-2 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
     <div class="flex justify-end">
        <button onClick={remove} class="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5" type="button">
             X 
        </button>
    </div>
    
    
    <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">{zone.Location.Name}</h5>
    
    <div className="mb-6">
      <label 
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
        Date Time</label>
      <DateTimePicker onChange={fromDateChange} value={fromDate}  />
    </div>

    </div>
  );
}