
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetClientList } from "../../Client/Api/ClientApi";

export default function useLocationList(companyId) {
  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    GetClientList(companyId).then(response => {
      console.log(response);
      setLocationList(response.data);
      setLoading(false);
    });
  },[companyId])

  return { locationList, loading, error }
}