import * as React from "react";
import { useCallback, useState } from "react";
import ZoneView from "./ZoneView";
import uuid from 'react-uuid';

export default function ZoneList({ zones, setZones, locations }) {

  const [ locationId, setLocationId ] = useState(null);
  //const [ zones, setZones ] = useState(zones || []);
 
  const addZone = () =>
  {
      if(locationId === null)
        return;

      const location = locations.find(loc => loc.Id === locationId);
      const today = new Date().toJSON().slice(0, 10);
      
      var newZone = { Id:uuid(), Location: location, From: today, To: today };
      console.log(zones);

      setZones([...zones, newZone]);
  };

  const removeZone = (index) => {
    setZones(zones.filter((_, i) => i !== index));
  }

  const updateZone = (zone) => {
    const newList = zones.map((item) => {
      if (item.Id === zone.Id) {
        //const updatedItem = {
          //...item,
          //isComplete: !item.isComplete,
        //};
        return zone;
      }
      return item;
    });
    setZones(newList);
  }


  const selectLocation = (event) => {
    setLocationId(event.target.value);
  }

  return (
    <div>
    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
       Zones
    </label>


    <select
    value={locationId} onChange={selectLocation}
     className="select select-bordered w-full max-w-xs">
    <option disabled selected>Select a Location</option>
      {locations?.map((location) => (<option value={location.Id}>{location.Name}</option>))}
    </select>

    <button 
      className="inline-flex mx-2 items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      onClick={addZone}>Add</button>

    {zones?.map((zone, index) => (<ZoneView zone={zone} update={updateZone} remove={() => removeZone(index)}  />))}
    </div>
  );
}