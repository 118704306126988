import axios from 'axios';
import Config from "../../../Configurations/Config"; 

export function GetShiftList(companyId) 
{
    const apiPayload = { Identity: { CompanyId:"", UserId:""} };
    return Exec("shifts/all", apiPayload);
}

export function EditShift(model) 
{
  const apiPayload = { Model: model };
  return Exec("shifts/edit", apiPayload);
}

export function GetShift(shiftId)
{
  const apiPayload = { ItemId: shiftId };
  return  Exec("shifts/get", apiPayload);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}