import React, { useEffect } from 'react';
import { ReactSession } from 'react-client-session';

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
//import AuditList from '../AuditList/AuditList';
import Login from "../Auth/Login/Login";
import Footer from '../Core/Footer';
import Header from '../Core/Header';
import Home from '../Home/Home';
//=======================================
import ClientList from '../Client/ClientList';
import ClientEdit from '../Client/ClientEdit';
//=========
import UserList from '../User/UserList';
import UserEdit from '../User/UserEdit';
//====
import ShiftList from '../Shift/ShiftList';
import ShiftEdit from '../Shift/ShiftEdit';
//========================================
import SupportPage from '../Support/SupportPage';
import SupportSent from '../Support/SupportSent';
//========
import LocationList from '../Location/LocationList';
import LocationEdit from '../Location/LocationEdit';

import Sidebar from "../Core/Sidebar"
import ShiftPreview from '../Shift/ShiftPreview';



export default function Content() {

  const currentUser = ReactSession.get("user"); 
  const atLogin = document.location.pathname.includes("/login");
  const navigate = useNavigate();

  useEffect(() => {
    if (!atLogin && !currentUser) {
      navigate("/auth/login");
    }
  }, [navigate, atLogin, currentUser]);


  var menuItems = [
    { name: "Shifts", key:"shifts", url: "/shifts" },
    { name: "Clients", key:"clients", url: "/clients" },
    { name: "Locations", key:"locations", url: "/locations" },
    { name: "Users", key:"users", url: "/users" },
    
    { name: "Support", key:"support", url: "/support" },
  ];

  var coreItems = [
    { name: "Log out", url: "/logout" }
  ];





  return (<div class="flex min-h-screen flex-row bg-gray-100 text-gray-800">
  <Sidebar menuItems={menuItems} coreItems={coreItems} />
  <main class="main -ml-64 flex flex-grow flex-col p-4 transition-all duration-150 ease-in md:ml-0">
    
       <Header />

       <div class="flex h-full p-3 bg-white shadow-md">
        <Routes>
                {/*<Route path="/" element={<AuditList />} />
                <Route path="/audits" element={<AuditList />} />*/}
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                {/*--------------------*/}
                <Route path="/clients" element={<ClientList />} />
                <Route path="/clients/edit/:clientId" element={<ClientEdit />} />
                {/*--------------------*/}
                <Route path="/users" element={<UserList />} />
                <Route path="/users/edit/:userId" element={<UserEdit />} />
                {/*--------------------*/}
                <Route path="/locations" element={<LocationList />} />
                <Route path="/locations/edit/:locationId" element={<LocationEdit />} />
                {/*--------------------*/}
                <Route path="/shifts" element={<ShiftList />} />
                <Route path="/shifts/edit/:shiftId" element={<ShiftEdit />} />
                <Route path="/shifts/preview/:shiftId" element={<ShiftPreview />} />
                {/*--------------------*/}
                <Route path="/support" element={<SupportPage/>}  />
                <Route path="/support/thank-you" element={<SupportSent/>} />
        </Routes>
        </div>

        <Footer />
    </main>
  </div>);
}