import * as React from 'react';
import Pagination from '../Core/Pagination';



import ItemView from "./ItemView";


export default function Home() {

  var items = [{ Name:"Clients", Url:"/clients" }, { Name:"Users", Url:"/users" }, { Name:"Shifts", Url:"/shifts" }];


  return (
    <div>
    <div class="flex flex-col container max-w-md  mt-10 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
    <ul class="flex flex-col divide-y w-full">
    {items.map((item) => (
         <ItemView item={item} />
    ))}
    </ul>
    </div>

    <footer>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="#" class="hover:underline">Trimax</a>. All Rights Reserved.
      </span>
    </footer>

    </div>
  );
}