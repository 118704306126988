import axios from 'axios';




export function SendSupport(form) 
{
    const request = {
        SupportRequest:{
          AppId:0,
          FacilityId:0,
          ProjectId:0,
          UserId:0,
          Version:"1.0"
        },
        Model:{
          Name: form.Name,
          Email: form.Email,
          Message: form.Message,
          AppName:"app.shifts.react",
          Platform:"web"
        }
      };
    return Exec(request);
}

function Exec(requestBody)
{
  console.log("api: " + JSON.stringify(requestBody));
  var url = `https://service-support.azurewebsites.net/api/support/send`;
  return axios.post(url, requestBody);
}