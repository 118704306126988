import axios from 'axios';
import Config from "../../../Configurations/Config"; 

export function ApiLogin(user) 
{
    const apiPayload = { Email : user.Email, Password: user.Password };
    return Exec("auth/login", apiPayload);
}

export function ApiRegister(user) 
{
  //const apiPayload = { Model: model };
  //return Exec("auth/register", apiPayload);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}