import logo from './logo.svg';
import './App.css';


import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";

import Auth from "./Components/Auth/Auth";
import Content from "./Components/Content/Content";

import { Toaster } from "react-hot-toast";
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

function App() {



  const currentUser = ReactSession.get("user"); 
  const atLogin = document.location.pathname.includes("/login");
  const navigate = useNavigate();

  useEffect(() => {
    if (!atLogin && !currentUser) {
      navigate("/auth/login");
    }
  }, [navigate, atLogin, currentUser]);


  return (
    <div>
     <div><Toaster/></div>
    
    <Routes>
      <Route path="/auth/*" element={<Auth />} />
      <Route path="/*" element={<Content />} />
    </Routes>
    </div>
  );
}

export default App;
