
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetUser } from "../Api/UserClient";


export default function useUserGet(companyId, userId) {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {

    setLoading(true);
    if(!userId || userId === "new")
    {
     const defaultState = {
            Id:"",
            CompanyId: companyId,
            Avatar: "",
            PersonId: "",
            Name:"",
            Email:"",
            JobTitle:"",
            Phone:"",
            Password:"",
            Roles:[],
            CreatedAt:"",
            RecordState:0
         }
         setUser(defaultState);
      setLoading(false);
      return;
    }

    GetUser(userId).then(response => {
        setUser(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[userId, companyId])

  return { user, setUser, loading, error }
}