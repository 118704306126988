import axios from 'axios';
import Config from "../../../Configurations/Config"; 

export function GetUserList(companyId) 
{
    const apiPayload = { Identity: { CompanyId:"", UserId:""} };
    return Exec("users/all", apiPayload);
}

export function EditUser(model) 
{
  const apiPayload = { Model: model };
  return Exec("users/edit", apiPayload);
}

export function GetUser(userId)
{
  const apiPayload = { ItemId: userId };
  return  Exec("users/get", apiPayload);
}

function Exec(apiAction, apiPayload)
{
  const requestBody = {
    Action: apiAction,
    Payload: apiPayload,
  };
  console.log("api: " + JSON.stringify(requestBody));
  return axios.post(Config.serverUrl, requestBody);
}