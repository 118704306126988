
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useMatch, useNavigate } from "react-router-dom";

import Breadcrumb from '../Core/Breadcrumb';
import Pagination from '../Core/Pagination';
import ItemView from './Components/ItemView';


import { GetUser, EditUser } from "./Api/UserClient"
import useUserGet from "./Hooks/useUserGet";
import { ReactSession } from 'react-client-session';

export default function UserEdit() {

  const navigate = useNavigate();
  const match = useMatch("/users/edit/:userId");
  var userId = match && match.params && match.params.userId;
  const currentUser = ReactSession.get("user"); 
  const companyId = JSON.parse(currentUser).CompanyId;

  //===========
  var breadcrumItems = [ {Name:"Home", Url:"/"}, {Name:"Users", Url:"/users"}, {Name:"Edit", Url:"#"} ];
  var items = [{ Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }];
  //===========
  const { user, setUser, loading } = useUserGet(companyId, userId);
  const [saving, setSaving ] = useState(false);
  //===========
  const handleOnBlur = e => {
    let elementName = e.target.name;
    let elementId = e.target.id;
    let attributeValue = e.target.value.toString().trim();
    //========
    if(elementId === "name") setUser({ ...user, Name: attributeValue });
    if(elementId === "email") setUser({ ...user, Email: attributeValue });
    if(elementId === "password") setUser({ ...user, Password: attributeValue });
    //========
  }


  const handleSubmit = event => {
    event.preventDefault();
    setSaving(true);
    const response = EditUser(user).then(response => {
      console.log(response.data);
      setSaving(false);
      if(userId === "new")
      {
        navigate(`/users`);
        return;
      }
      toast.success("Saved");
    });
  };


  return (
    <div className="container mx-auto w-full">
    <div className="flex flex-col container mx-auto w-full">
    <Breadcrumb items={breadcrumItems} />
    </div>

    <div class="container mx-auto my-4">
    {loading ? <>Loading ...</> :
    <>
    <form onSubmit={handleSubmit}>
    <div class="mb-6">
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
        <input type="text" 
          id="name" onBlur={handleOnBlur}
          defaultValue={user && user.Name}
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Name" required/>
    </div> 

    <div class="mb-6">
        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
        <input type="email" 
          id="email" onBlur={handleOnBlur}
          defaultValue={user && user.Email}
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="john.doe@company.com" required/>
    </div>

    <div class="mb-6">
        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Password</label>
        <input type="password" 
          id="password" onBlur={handleOnBlur}
          defaultValue={user && user.Password}
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="*******" required/>
    </div> 

    <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
      {saving ? <>Sending</>:<>Save</>}
    </button>
    </form>
    </>}

    </div>
    </div> 

  );
}