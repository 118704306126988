
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetLocationList } from "../Api/LocationClient";

export default function useLocationList(companyId) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    GetLocationList(companyId).then(response => {
        console.log(response);
        setData(response.data);
        setLoading(false);
    });

  },[companyId])

  return { data, loading, error }
}