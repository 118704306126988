import React from "react";
import GoogleMapReact from 'google-map-react';

/*
import { Icon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/map-marker'
const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon icon={locationIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
)*/

import {  BsFillRecordCircleFill  } from "react-icons/bs";
import {  IoMdPin  } from "react-icons/io";
import {  FiActivity, FiLogOut, FiLifeBuoy, FiUsers, FiUserCheck, FiHome  } from "react-icons/fi";

const AnyReactComponent = ({ text }) => <div><BsFillRecordCircleFill className="h-5 w-5 text-red-600" /></div>;

export default function SimpleMap( { gpsLocation } ){


  

  const defaultLocation = {
    lat: 37.42216,
    lng: -122.08427,
  }

  const location = (gpsLocation && {  lat: gpsLocation.Latitude, lng: gpsLocation.Longitude }) || defaultLocation;



  const zoom = 15;



  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyA8t1kGNfVIMskSzUjoG58g8cpRLVcxDiA" }}
        defaultCenter={location}
        defaultZoom={zoom}
      >

        <AnyReactComponent
          lat={location.lat}
          lng={location.lng}
        />
      </GoogleMapReact>
    </div>
  );
}