import { useCallback, useState } from "react";

import useLogin from "./Hooks/useLogin";
import logo from "../Assets/Images/logo.png"; 

import toast from 'react-hot-toast';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';


export default function Login() {
    
    const navigate = useNavigate();

    const [ user, setUser ] = useState({ Email:"", Password:""});
    const { login, loading } = useLogin(user);
  

    const handleOnBlur = e => {

      let elementName = e.target.name;
      let elementId = e.target.id;
      let attributeValue = e.target.value.toString().trim();
      //==
      if(elementId === "email") setUser({ ...user, Email: attributeValue });
      if(elementId === "password") setUser({ ...user, Password: attributeValue });
    }



    const handleSubmit = event => {
        event.preventDefault();
        login(user);
        //setSaving(true);
        //SendSupport(form).then(response =>{
        //  setSaving(false);
        //  navigate("/support/thank-you");
        //});
      };


    const handleSignup = ()=>
    {
        toast.success("Not implemented");
    };

    const handleForgotPassword = ()=>
    {
        toast.success("Not implemented");
    };


  return (
<section className="bg-gray-50 dark:bg-gray-900">
  <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img src={logo} className="w-20 h-20 mr-2" alt="logo" />
          Trimax Shifts    
      </a>
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Sign in to your account
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                  <div>
                      <label htmlFor="email" id="email" 
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input type="email"  name="email" id="email" onChange={handleOnBlur} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
                  </div>
                  <div>
                      <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input type="password" name="password" id="password" onChange={handleOnBlur} placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                  </div>
                  {/* 
                  <div className="flex items-center justify-between">
                      <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                          </div>
                      </div>
                      <a href="#" onClick={handleForgotPassword} className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
                  </div>
                  */}
                  <button  type="submit" className="w-full text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-black-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center   ">
                      {loading ? <>Loading ...</> : <>Sign In</>}
                  </button>
                  {/* 
                  <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                      Don’t have an account yet? <a href="#" onClick={handleSignup} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
                  </p>
                  */}
              </form>
          </div>
      </div>
  </div>
</section>
     

  );
}