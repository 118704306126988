
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetClient } from "../Api/ClientApi";


export default function useClientGet(companyId, clientId) {

  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {

    setLoading(true);
    if(!clientId || clientId === "new")
    {
     const defaultState = {
            Id:"",
            CompanyId: companyId,
            Name:"",
            Address:"",
            Description:"",
            RecordState:0
         }
         setClient(defaultState);
      setLoading(false);
      return;
    }

    GetClient(clientId).then(response => {
        setClient(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[clientId, companyId])

  return { client, setClient, loading, error }
}