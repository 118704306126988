import * as React from 'react';


import logo from "../Assets/Images/logo.png";


const roleList = [
  { Name: "Admin", Value: 2 },
  { Name: "User", Value: 3 },
  { Name: "Client", Value: 4 },
  { Name: "Agent", Value: 5 } ];


export default function ItemView({ item }) {




  var foundRole = roleList.find(f => item.Roles != null && item.Roles.length > 0 && f.Value === item.Roles[0]);
  console.log(foundRole);

  return (
    <a href={`/users/edit/${item.Id}`} class="block relative">
    <li class="flex flex-row">
    <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
      <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
          <img alt="profil" src={logo} class="mx-auto object-cover rounded-full h-10 w-10" />
      </div>

      <div class="flex-1 pl-1">
        <div class="font-medium dark:text-white text-sm">{item.Name}</div>
        <div class="text-gray-600 dark:text-gray-200 text-sm">{item.Email}</div>
      </div>
   
      <div class="flex-1 pl-1">
        <div class="font-medium dark:text-white text-sm">Role</div>
        <div class="text-gray-600 dark:text-gray-200 text-sm">{foundRole?.Name}</div>
      </div>


      <div class="flex flex-row justify-center">
        <button type="button" class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Shifts</button>
      </div>
    </div>
  </li>
  </a>
  );

}