import React, { useState } from "react";
import { Modal, Button } from "flowbite-react";
import SimpleMap from "./SimpleMap";

export default function MapModal({ gpsLocation }) 
{

  const [showModal, setShowModal] = useState(false);
    const onClose = () =>{ setShowModal(false); };
    const onClick = () =>{ setShowModal(false); };

  return (
    <>

    <button type="button" onClick={() => setShowModal(true)} class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
      Google Map
    </button>



      <Modal
    show={showModal}
    onClose={onClose}
  >
    <Modal.Header>
      User Location
    </Modal.Header>
    <Modal.Body>
     <SimpleMap gpsLocation={gpsLocation}/>
    </Modal.Body>
   
  </Modal>


    </>
  );
};

