import * as React from 'react';

import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import ThankYouIcon from "./Assets/Images/check-icon.png";

export default function SupportSent() {

  const navigate = useNavigate();

  return (
    <div class="container mx-auto px-4">
    <img class="max-w-lg h-auto" src={ThankYouIcon} />
    <h3><b>Thank You</b></h3>
    <div>Your message has been sent.</div>

    <a href="/jobs">
    <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Back to Home Page</button>
    </a>
    </div>

  );
}