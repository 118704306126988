
import { BeakerIcon } from '@heroicons/react/solid'
import { getActiveElement } from '@testing-library/user-event/dist/utils';
import { FiActivity, FiLogOut, FiLifeBuoy, FiUsers, FiUserCheck, FiHome  } from "react-icons/fi";

import { ReactSession } from 'react-client-session';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

export default function Sidebar({ menuItems, coreItems }) {
    const navigate = useNavigate();

    const getIcon = (name) =>
    {
        if(name === "support")
            return (<FiLifeBuoy className="h-5 w-5 text-blue-500" />);
        if(name === "users")
            return (<FiUsers className="h-5 w-5 text-blue-500" />);
        if(name === "clients")
            return (<FiUserCheck className="h-5 w-5 text-blue-500" />);
        if(name === "locations")
            return (<FiHome className="h-5 w-5 text-blue-500" />);

        return (<FiActivity className="h-5 w-5 text-blue-500" />);
    };

    const handleLogout = () => {
        ReactSession.set("user", null);
        navigate("/auth/login");
      };

    return (

      <aside class="sidebar w-64 -translate-x-full transform bg-white p-4 transition-transform duration-150 ease-in md:translate-x-0 md:shadow-md" aria-label="Sidenav">
      <div class="overflow-y-auto py-5 px-3 h-full bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      
      <ul class="space-y-2">
          {menuItems && menuItems.map((item, index) => (
              <li key={index}>
              <a href={item.url} class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  {getIcon(item.key)}
                  <span class="ml-3">{item.name}</span>
              </a>
              </li>
          ))}
      </ul>

      <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
          {coreItems && coreItems.map((item, index) => (
              <li key={index}>
              <a href='#' onClick={handleLogout} class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  <FiLogOut className="h-5 w-5 text-blue-500" />
                  <span class="ml-3">{item.name}</span>
              </a>
              </li>
          ))}
      </ul>

      </div>
      </aside>
    );
  }


