

import React from "react";
import GoogleMapReact from 'google-map-react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


import DefaultImg from "./no-image.webp"


export default function PictureList(){
    const [open, setOpen] = React.useState(false);

  return (
    // Important! Always set the container height explicitly
    <div class="container  mx-auto">
    <div class="flex flex-wrap">
      <div class="flex flex-wrap w-20 h-20">
        <div class="w-full p-1">
          <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={DefaultImg}/>
        </div>
      </div>
      <div class="flex flex-wrap w-20 h-20">
        <div class="w-full p-1">
          <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={DefaultImg}/>
        </div>
      </div>
      <div class="flex flex-wrap w-20 h-20">
        <div class="w-full p-1">
          <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={DefaultImg}/>
        </div>
      </div>
    
     
    </div>




  </div>
  );
}