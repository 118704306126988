import * as React from 'react';
import toast from 'react-hot-toast';

import Breadcrumb from '../Core/Breadcrumb';
import Pagination from '../Core/Pagination';
import ItemView from './Components/ItemView';

import { useMatch, useNavigate } from "react-router-dom";
import useUserList from "./Hooks/useUserList";
import { ReactSession } from 'react-client-session';

export default function UserList() {

  var breadcrumItems = [ {Name:"Home", Url:"/"}, {Name:"Users", Url:"/users"} ];

  const currentUser = ReactSession.get("user"); 
  const companyId = JSON.parse(currentUser).CompanyId;

  /*const {
    params: { companyId },
  } = useMatch("/project/:projectId/experiments");*/
  const { data, loading } = useUserList(companyId);

  var items = [{ Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }, { Form:"Form1" }];

  return (
  <div className="container mx-auto w-full">

  <div className="flex flex-col container mx-auto w-full">
    <Breadcrumb items={breadcrumItems} />
  </div>

  <a href={`/users/edit/new`}
    type="button" 
    class="text-white mt-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
      Create
  </a>

  {loading ? <>Loading ...</> :
  <>
  <div className="flex flex-col container  mt-2 mx-auto w-full items-center justify-center bg-white dark:bg-gray-800 rounded-lg shadow">
  <ul className="flex flex-col divide-y w-full">
  {data.map((item, index) => (
         <ItemView item={item} key={index} />
    ))}
  </ul>
  </div>
  <div className="flex pt-4 justify-center">
  <Pagination />
  </div>
  </>
  }

  </div>


  );
}