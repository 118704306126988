
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Login from "./Login/Login";
import Logout from "./Logout/Logout";

export default function Auth() {
  const navigate = useNavigate();
  const location = useLocation();
  if (location.pathname === "/") navigate("/projects");

  return (
    <div>
        <Routes>
              <Route path="login" element={<Login />} />
              {/* <Route path="register" element={<Register />} /> */}
              <Route path="logout" element={<Logout />} />
        </Routes>
    </div>

  );
}