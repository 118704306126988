import * as React from 'react';


import logo from "../Assets/Images/location.png";

export default function ItemView({ item }) {


  return (
    <a href={`/locations/edit/${item.Id}`} class="block relative">
    <li class="flex flex-row">
    <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-4">
      <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
          <img alt="profil" src={logo} class="mx-auto object-cover rounded-full h-10 w-10" />
      </div>

      <div class="flex-1 pl-1">
        <div class="font-medium dark:text-white text-sm">{item.Name}</div>
        <div class="text-gray-600 dark:text-gray-200 text-sm">Location</div>
      </div>
    </div>
    </li>
    </a>
  );

}