
import { useCallback, useState } from "react";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";


import axios from "axios";
import { ReactSession }  from 'react-client-session';


import { ApiLogin } from "../../Api/AuthClient";

export default function useLogin(user) {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const login = useCallback(async () => {
    try {
      setLoading(true);


      console.log(user);

      const response = await ApiLogin(user);
      const operationResponse = response.data;

      console.log(response);

      if(!operationResponse.Success)
      {
        toast.error(operationResponse.Message);
        return;
      }

      const currentUser = operationResponse.User;
      ReactSession.set("user", JSON.stringify(currentUser));

      navigate("/users");
      
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [user, navigate]);

  return { login, loading };
}
