
import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetLocation } from "../Api/LocationClient";


export default function useLocationGet(companyId, locationId) {

  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {

    setLoading(true);
    if(!locationId || locationId === "new")
    {
     const defaultState = {
            Id:"",
            CompanyId: companyId,
            Name:"",
            Address:"",
            RecordState:0
         }
         setLocation(defaultState);
      setLoading(false);
      return;
    }

    GetLocation(locationId).then(response => {
        setLocation(response.data);
        console.log(response.data);
        setLoading(false);
    });

  },[locationId, companyId])

  return { location, setLocation, loading, error }
}